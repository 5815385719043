import type { HeaderNavLink } from './header';

export const setAccountHeaderData = (anonID: string): HeaderNavLink[] => {
  return [
    {
      linkUrl: '#',
      linkLabel: 'My Account',
      isDropDown: true,
      items: [
        {
          linkUrl: `https://app.aceable.com/user/signin?v=RE${anonID ? `&ajs_aid=${anonID}` : ''}`,
          linkLabel: 'Sign In',
          newTab: false,
          nofollow: false,
        },
        {
          linkUrl: 'https://www.aceableagent.com/support/',
          linkLabel: 'Help Center',
          newTab: false,
          nofollow: false,
        },
      ],
    },
  ];
};

export const headerData: HeaderNavLink[] = [
  {
    linkUrl: '/real-estate-license/',
    linkLabel: 'Get My License',
    isDropDown: true,
    items: [
      {
        linkUrl: '/real-estate-license/arizona/',
        linkLabel: '<strong/>Arizona </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/california/',
        linkLabel: '<strong/>California </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/florida/',
        linkLabel: '<strong/>Florida </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/georgia/',
        linkLabel: '<strong/>Georgia </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/illinois/',
        linkLabel: '<strong>Illinois </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/michigan/',
        linkLabel: '<strong/>Michigan </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/new-york/',
        linkLabel: '<strong/>New York </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/north-carolina/',
        linkLabel: '<strong/>North Carolina </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/pennsylvania/',
        linkLabel: '<strong/>Pennsylvania </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/south-carolina/',
        linkLabel: '<strong/>South Carolina </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/texas/',
        linkLabel: '<strong/>Texas </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/tennessee/',
        linkLabel: '<strong/>Tennessee </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/virginia/',
        linkLabel: '<strong/>Virginia </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/washington/',
        linkLabel: '<strong/>Washington </strong> Real Estate License',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-license/',
        linkLabel: '<strong>Browse All States</strong>',
        newTab: false,
        nofollow: false,
        arrowLink: true,
      },
    ],
  },
  {
    linkUrl: '#',
    linkLabel: 'More Courses',
    isDropDown: true,
    items: [
      {
        linkUrl: '/real-estate-exam-prep/',
        linkLabel: 'Exam Prep',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/post-licensing/',
        linkLabel: 'Post Licensing',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-continuing-education/',
        linkLabel: 'Continuing Education',
        newTab: false,
        nofollow: false,
      },
    ],
  },
  {
    linkUrl: '/about/',
    linkLabel: 'About',
    isDropDown: true,
    items: [
      {
        linkUrl: '/blog/',
        linkLabel: 'Blog',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/career-center/',
        linkLabel: 'Career Center',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/real-estate-partnerships/',
        linkLabel: 'Partnerships',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/reviews/',
        linkLabel: 'Reviews',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/about/',
        linkLabel: 'About AceableAgent',
        newTab: false,
        nofollow: false,
      },
    ],
  },
];
