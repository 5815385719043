import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { FooterNavLink } from './footer';
import { footerData } from './footer-data';

@Component({
  selector: 'aarp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  showHideFooter = true;
  footerData: FooterNavLink[] = footerData;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private route: ActivatedRoute) {
    if (this.route.snapshot.data?.pageData?.craftData.headerData) {
      this.showHideFooter = this.route.snapshot.data.pageData?.craftData?.headerData[0]?.hideFooter;
    }
  }
}
