import { DOCUMENT } from '@angular/common';
import type { AfterViewInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { CountdownService } from '@core-mkt/services/countdown/countdown.service';
import { cloneDeep } from 'lodash-es';
import type { EmailCapture, Footer, FooterLinkGroup, FooterLogo, ShareButtons } from './footer';
import { footerData } from './footer-data';

@Component({
  selector: 'footer, aa-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements AfterViewInit {
  pageData: Footer;
  emailCapture: EmailCapture;
  resolverData: PageDataResponse;
  displayRE = false;
  promoBannerActive = true;
  showStickyEmailCapture = true;
  graphicBanner: HTMLElement;
  showPrivacyManagerModal = false;
  private readonly copyrightYear_ = new Date().getFullYear();

  constructor(
    private route: ActivatedRoute,
    private countdownService: CountdownService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.pageData = footerData;

    if (this.route.snapshot.data.pageData) {
      this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
      if (this.resolverData.craftData.header) {
        this.emailCapture = this.resolverData.craftData.header?.emailCapture[0];
        const aaGraphicPromoBanner = this.resolverData.craftData.header?.aaGraphicPromoBanner[0];
        const realEstateHeaderPromoBanner = this.resolverData.craftData.header?.realEstateHeaderPromoBanner[0];
        this.promoBannerActive = realEstateHeaderPromoBanner.showHidePromoBanner;
        if (
          aaGraphicPromoBanner &&
          aaGraphicPromoBanner.showHideGraphicPromoBanner &&
          realEstateHeaderPromoBanner.showHidePromoBanner
        ) {
          const startTime = aaGraphicPromoBanner.startTime;
          const endTime = aaGraphicPromoBanner.endTime;

          if (startTime == null && endTime == null) {
            this.displayRE = true;
          } else if (startTime && endTime) {
            this.displayRE = this.countdownService.displayBanner(startTime, endTime);
          }
        }
      }
      if (this.resolverData.craftData.banners && this.resolverData.craftData.banners[0]) {
        const promoData = this.resolverData.craftData.banners[0].topBanner[0];
        if (promoData.showHidePromoBanner) {
          const startTime = promoData.promotionStartDateTime;
          const endTime = promoData.promotionEndsDateTime;

          if (startTime == null && endTime == null) {
            this.displayRE = true;
          } else if (startTime && endTime) {
            const startTimeStamp = new Date(startTime).getTime();
            const endTimeStamp = new Date(endTime).getTime();
            this.displayRE = this.countdownService.displayBanner(startTimeStamp, endTimeStamp);
          }
        }
      }
    } else {
      this.pageData.emailCapture[0];
    }
  }

  ngAfterViewInit(): void {
    this.graphicBanner = this.document.getElementById('graphicBanner');
  }

  get copyrightYear(): number {
    return this.copyrightYear_;
  }

  get showHideFooter(): boolean {
    if (this.resolverData.craftData.headerData) {
      return this.resolverData.craftData.headerData.hideFooter;
    }
    return this.pageData.aceShowHideFooterNav;
  }

  get footerLogos(): FooterLogo[] {
    return this.pageData.aceFooterLogo;
  }

  get generalLinks(): FooterLinkGroup[] {
    return this.pageData.aceGeneralLinkGroup;
  }

  get generalLinks2(): FooterLinkGroup[] {
    return this.pageData.aceGeneralLinkGroup2;
  }

  get socialLinks(): FooterLinkGroup[] {
    return this.pageData.aceSocialLinkGroup;
  }

  get emailCaptureData(): EmailCapture {
    return this.emailCapture;
  }

  get shareButtons(): ShareButtons[] {
    return this.pageData.aceShareGroup;
  }

  itemLabel(button: ShareButtons): string {
    return button.label;
  }

  stripLink(rawLink: string): string {
    return rawLink.replace(/\w+:\/\//, '');
  }

  openPrivacyManagerModal() {
    this.showPrivacyManagerModal = true;
  }
}
