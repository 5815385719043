/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';
import { HeaderFragment, SettingsFragment } from './fragments/header-fragment-query';
import { SEOCareerCenterREFragment } from './fragments/seo-fragment-query';

export const TemplateCareerCenterQuery = {
  name: 'TemplateCareerCenterQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        sectionHandle
        typeHandle
        ... on aceRealEstateTemplates_careerCenterTemplate_Entry {
          hideStickyBanner
          id
          careerCenterTemplate {
            ... on careerCenterTemplate_sideProductPicker_BlockType {
              typeHandle
              productPicker {
                ... on productPicker_BlockType {
                  backgroundColor
                  ctaLabel
                  ctaType
                  productPickerHeading
                  productCategory {
                    ... on productPickerCategories_Category {
                      productPickerTable {
                        noFollow
                        noReferrer
                        modal
                        productLink
                        productName
                        state
                      }
                    }
                  }
                }
              }
              sideContent
              mobileBannerOptions {
                ... on mobileBannerOptions_BlockType {
                  displayMobileBanner
                  backgroundColor
                  bannerText
                  ctaLabel
                  ctaType
                }
              }
            }
            ... on careerCenterTemplate_breadcrumbs_BlockType {
              typeHandle
              backgroundColor
              textColor
              hideOnMobile
              breadcrumbsList {
                ... on breadcrumbsList_BlockType {
                  breadcrumbLink
                  breadcrumbText
                }
              }
            }
            ... on careerCenterTemplate_sliderCard_BlockType {
              typeHandle
              backgroundColor
              textColor
              sliderTitle
              sliderDescription
              displayAllCardMobile
              slidesCards {
                ... on slidesCards_BlockType {
                  slideImage {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                      height
                      width
                    }
                  }
                  slideContent
                  slideButtonStyle
                  slideButtonLabel
                  slideButtonLink
                  slideButtonNewTab
                  slideButtonNofollow
                }
              }
            }
            ... on careerCenterTemplate_anchor_BlockType {
              typeHandle
              anchorName
            }
            ... on careerCenterTemplate_anchorButton_BlockType {
              typeHandle
              backgroundColor
              buttonAlignment
              buttonLabel
              scrollTo
              buttonStyle
              buttonArrowDirection
            }
            ... on careerCenterTemplate_graphicBanner_BlockType {
              typeHandle
            }
            ... on careerCenterTemplate_layout_BlockType {
              typeHandle
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              textColor
              image {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              imageAlignment
              layoutTitle
              layoutContent
              layoutRightContent
              dropdownTopLabel
              dropdownPlaceholder
              dropdown {
                ... on dropdown_BlockType {
                  dropdownLabel
                  dropdownLink
                  dropdownLinkNewTab
                  dropdownLinkNofollow
                }
              }
              buttonLayout
              buttonAlignment
              buttons {
                ... on buttons_BlockType {
                  buttonLabel
                  buttonLink
                  buttonLinkNewTab
                  buttonLinkNofollow
                  buttonType
                }
              }
            }
            ... on careerCenterTemplate_tabComponent_BlockType {
              backgroundColor
              tabbedContentTitle
              typeHandle
              tab {
                ... on tab_BlockType {
                  isActive
                  tabContentList {
                    ... on tabContentList_accordionItem_BlockType {
                      itemDescription
                      itemTitle
                      showDescription
                      accordionSectionTitle {
                        ... on accordionSectionTitle_BlockType {
                          sectionTitle
                        }
                      }
                    }
                  }
                  tabTitle
                }
              }
            }
            ... on careerCenterTemplate_multiColumn_BlockType {
              tailwindClasses
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              columnList: columnList {
                ... on columnList_BlockType {
                  columnContent
                  dropdownTitle
                  dropdownAlignment
                  dropdown {
                    dropdownLabel
                    dropdownLink
                    dropdownLinkNewTab
                    dropdownLinkNofollow
                  }
                }
              }
              componentTitle
              textColor
              twoColumnWidthDistribution
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
              typeHandle
            }
            ... on careerCenterTemplate_accordion_BlockType {
              accordionDisplayType
              accordionItems {
                ... on accordionItems_BlockType {
                  itemDescription
                  itemTitle
                  accordionSectionTitle {
                    ... on accordionSectionTitle_accordionSectionTitle_BlockType {
                      sectionTitle
                    }
                  }
                  accordionImage {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                }
              }
              accordionTitle
              accordionLeftContent
              buttonLabel
              buttonLink
              buttonLinkNewTab
              buttonLinkNofollow
              buttonType
              typeHandle
            }
            ... on careerCenterTemplate_emailForm_BlockType {
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              ctaLabel
              ctaType
              formContent
              formType
              hideContentOnMobile
              formVertical
              showNameField
              showParentEmailField
              showPhoneNumberField
              hideEmailField
              emailFormImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              imageAlignment
              subscriptionListIds
              textColor
              typeHandle
            }
            ... on careerCenterTemplate_careerCenter_BlockType {
              typeHandle
              backgroundColor
              textColor
              careerCenterTitle
              careerCenterSideContent
              filterTitle
              filterDefaultLabel
              careerCenterArticleCards {
                ... on careerCenterArticleCards_BlockType {
                  image {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                  relatedResourceContent
                  relatedResourceLink
                  relatedResourcesCategory {
                    ... on careerCenterCategories_Category {
                      aceCategoryCode
                      title
                    }
                  }
                }
              }
            }
            ... on careerCenterTemplate_resources_BlockType {
              typeHandle
              resourcesBackgroundColor
              resourcesBackgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              resourcesTextColor
              resourcesTitle
              resourceCards {
                ... on resourceCards_BlockType {
                  resourceImage {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                  resourceBackgroundColor
                  resourceTextContent
                  resourceCtaStyle
                  resourceCtaLabel
                  resourceCtaLink
                  resourceCtaLinkNewTab
                  resourceCtaLinkNofollow
                }
              }
            }
            ... on careerCenterTemplate_layout_BlockType {
              backgroundColor
              buttonAlignment
              buttonLayout
              dropdownTopLabel
              dropdownPlaceholder
              imageAlignment
              layoutTitle
              layoutContent
              layoutRightContent
              typeHandle
              textColor
              image {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              buttons {
                ... on buttons_BlockType {
                  buttonLabel
                  buttonLink
                  buttonLinkNewTab
                  buttonLinkNofollow
                  buttonType
                }
              }
              dropdown {
                ... on dropdown_BlockType {
                  dropdownLabel
                  dropdownLink
                  dropdownLinkNewTab
                  dropdownLinkNofollow
                }
              }
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
            }
            ... on careerCenterTemplate_learnMore_BlockType {
              typeHandle
              backgroundColor
              textColor
              learnMoreContent
              componentPadding
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              ctaStyle
              ctaLabel
              ctaNofollow
              ctaNewTab
              ctaLink
              layout
            }
            ... on careerCenterTemplate_quiz_BlockType {
              typeHandle
              backgroundColor
            }
            ... on careerCenterTemplate_quizWidget_BlockType {
              typeHandle
              backgroundColor
              quizWidgetId
            }
          }
        }
      }
      seoContent: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_careerCenterTemplate_Entry {
          ...RealEstateSEOFragmentEntry
        }
      }
      styles: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_careerCenterTemplate_Entry {
          embedStyles
        }
      }
      optimizely: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_careerCenterTemplate_Entry {
          optimizelyEnabled
        }
      }
      globalOptimizely: globalSets(handle: "globalOptimizely") {
        ... on globalOptimizely_GlobalSet {
          optimizelyAceAgentTemplates {
            ... on optimizelyAceAgentTemplates_aceAgentTemplateTypes_BlockType {
              optimizelyEnabled: careerCenterAATemplateOpti
            }
          }
        }
      }
      structuredData: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_careerCenterTemplate_Entry {
          structuredData
        }
      }
      overrides: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_careerCenterTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
      }
      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
    }
    ${HeaderFragment.header}
    ${SettingsFragment.settings}
    ${SEOCareerCenterREFragment.seoContent}
  `,
};
