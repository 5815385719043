<div [class.container]="!isChild">
  <div *ngIf="accordionTitle" class="wysiwyg py-v-spacing sm:p-v-spacing text-center" [innerHTML]="accordionTitle"></div>
  <div 
    class="flex flex-col md:flex-row mx-auto"
    [ngClass]="{'max-w-vision': !accordionImage}"
    >
    <div *ngIf="showLeftContent && !accordionImage" class="py-v-spacing sm:px-v-spacing w-full md:w-2/5 md:mr-v-spacing">
      <div class="wysiwyg" [innerHTML]="accordionLeftContent"></div>
      <div *ngIf="!!buttonLabel" class="button-section mt-8">
        <vision-button defaultStyle="primary" [data]="buttonData"></vision-button>
      </div>
    </div>
    <div 
      class="flex w-full"
      [ngClass]="{'items-center flex-col md:flex-row': accordionImage}"
      >
      <div 
        class="accordion w-full {{ accordionPadding }}"
        [ngClass]="{'lg:w-1/2': accordionImage}"
      >
        <div *ngFor="let accordionItem of data.accordionItems; let first = first; let last = last">
          <div
            *ngIf="accordionItem?.accordionSectionTitle[0]?.sectionTitle"
            [innerHTML]="accordionItem.accordionSectionTitle[0].sectionTitle"
            class="section-title py-v-spacing sm:p-v-spacing mb-3 !pt-12"
          ></div>
          <div
            class="accordion-item {{
              accordionType !== ' bg-white' ? 'border-color-ace-gray-400 py-v-spacing mb-5' : 'boxed p-v-spacing !mb-3'
            }}"
            [class.first-item]="first"
            [class.last-item]="last"
            [ngClass]="'accordion-item' + accordionType"
            (keyup.enter)="toggle(accordionItem)"
            tabindex="0"
          >
            <div class="wrapper">
              <div class="w-full">
                <div
                  class="group item-title flex justify-between items-center cursor-pointer"
                  (click)="toggle(accordionItem)"
                >
                  <div class="title w-4/5 wysiwyg headingConfig" [innerHTML]="accordionItem.itemTitle"></div>
                  <div class="icon text-ace-blue-600 group-hover:text-ace-blue-500 text-base text-lg mt-1">
                    <div *ngIf="!accordionItem.showDescription">
                      <span [innerHTML]="openIcon"></span>
                    </div>
                    <div *ngIf="accordionItem.showDescription"> 
                      <span [innerHtml]="closeIcon"></span>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="accordionItem.showDescription"
                  class="item-description w-full mt-4 wysiwyg"
                  [innerHTML]="accordionItem.itemDescription"
                ></div>
                <!-- Mobile accordion image -->
                <div *ngIf="accordionImage" class="lg:hidden">
                  <img class="pt-v-spacing" *ngIf="accordionItem.showDescription" [src]="accordionImageURL(accordionItem.accordionImage[0].url)">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Desktop accordion image -->
      <div *ngIf="accordionImage" class="accordion-image hidden lg:block w-1/2 py-v-spacing sm:px-v-spacing">
        <div *ngFor="let accordionItem of data.accordionItems">
          <img *ngIf="accordionItem.showDescription" [src]="accordionImageURL(accordionItem.accordionImage[0].url)">
        </div>
      </div>
    </div>
  </div>
</div>