/* eslint-disable prettier/prettier */
import { ProductFragment } from '@core-mkt/shared/craft/product-entry-fragment';
import * as gql from 'fake-tag';

const accordionItems = `
              itemBadge
              itemValueDescriptor
              itemDescription
              itemTitle
              itemIcon {
                url
                ... on images_Asset {
                  altText
                }
              }
              showDescription`;

export const BundleFragment = {
  Bundle: gql`
    fragment BundleQuery on baseComponents_bundle_BlockType {
      bundleAccordionItems1 {
        ... on bundleAccordionItems1_BlockType {
          ${accordionItems}
        }
      }
      bundleAccordionItems2 {
        ... on bundleAccordionItems2_BlockType {
          ${accordionItems}
        }
      }
      bundleAccordionItems3 {
        ... on bundleAccordionItems3_BlockType {
          ${accordionItems}
        }
      }
      topContent
      bundles {
      ... on bundles_BlockType {
        bottomContent
        ctaLabel
        ctaLinkNewTab
        ctaLinkNofollow
        ctaLinkOverride
        ctaStyle
        principalContent
        product {
          ...ProductEntry
        }
        topContent
        topContentStyles
      }
    }
    lineBreak_bundlesAccordion
    lineBreak_bundlesMainContent
    lineBreak_styles
    styles
    typeHandle
    }
  ${ProductFragment.product}`,
};
