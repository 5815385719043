import { Component, Input } from '@angular/core';
import { MenuItem } from '../../header/header';
import { faCaretRight, faCaretDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { stateList } from '../../header/state-nav-data';

@Component({
  selector: 'dec-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent {
  @Input() menuGroupList: MenuItem[][];
  @Input() _showLogoHref = true;
  _menuGroups: MenuItem[][];
  hidden = true;
  statesHidden = false;
  faCaretRight: IconDefinition = faCaretRight;
  faCaretRightStyles = { width: '7.5px', height: '30px' };
  faCaretDown: IconDefinition = faCaretDown;
  faCaretDownStyles = { width: '12.5px', height: '30px' };

  get displayMenu(): boolean {
    if (this.menuGroupList === undefined) {
      return false;
    }
    const menuGroupListLength = this.menuGroupList.length;
    return menuGroupListLength === 0 ? false : true;
  }

  get stateSelectionList(): MenuItem[] {
    return stateList;
  }

  get showLogoHref(): boolean {
    return this._showLogoHref;
  }

  constructor() {}

  mobileMenuToggle() {
    this.hidden = !this.hidden;
  }

  mobileStatesToggle() {
    this.statesHidden = !this.statesHidden;
  }

  onEnter(): void {
    this.mobileMenuToggle();
  }
}
