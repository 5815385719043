/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const ResourcesFragment = {
  Resources: gql`
    fragment ResourcesQuery on baseComponents_resources_BlockType {
      lineBreak_content
      lineBreak_styles
      resourceCards {
        ... on resourceCards_BlockType {
          resourceBackgroundColor
          resourceCtaLabel
          resourceCtaLink
          resourceCtaLinkNewTab
          resourceCtaLinkNofollow
          resourceCtaStyle
          resourceImage {
            url
            ... on images_Asset {
              altText
            }
          }
          resourceTextContent
        }
      }
      resourcesBackgroundImage {
        url
        ... on images_Asset {
          altText
        }
      }
      resourcesTitle
      styles
      typeHandle
    }
  `,
};
