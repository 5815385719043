import { Injectable } from '@angular/core';

import type { Image } from '@core-mkt/interfaces/image';

@Injectable({
  providedIn: 'root',
})
export class ThemeParserService {
  filteredTailwindClasses: string[] = ['bg-', 'border-', 'shadow', 'opacity', 'blur'];
  constructor() {}

  getThemeClasses(theme: ComponentTheme): ThemeInterface {
    const componentTheme: ThemeInterface = {
      root: '',
      container: '',
      columnWidths: [],
      columnOrder: '',
      bgImage: '',
      linkColor: '',
    };
    componentTheme.columnOrder = this.getColumnOrder(theme);
    componentTheme.root = this.getRootClasses(theme);
    componentTheme.container = this.getContainerClasses_v3(theme);
    if (theme.backgroundImage) {
      componentTheme.bgImage = 'background-image: url(' + theme.backgroundImage.url + ')';
    }
    componentTheme.linkColor = this.getLinkColor(theme);
    componentTheme.columnWidths = this.getColumnWidths(theme);
    return componentTheme;
  }

  getColumnOrder(theme: ComponentTheme): string {
    if (theme.columnDistribution) {
      return theme.columnDistribution;
    }
    return '';
  }

  getColumnWidths(theme: ComponentTheme): string[] {
    const columnWidths: string[] = [];
    for (let i = 0; i < theme.columnCount; i++) {
      if (theme.columnCount === 2 && theme.columnDistribution === 'left') {
        //60/40 split default for two-column layout
        columnWidths.push('md:w-' + (3 - i) + '/5');
      } else if (theme.columnCount === 2 && theme.columnDistribution === 'right') {
        //40/60 split default for two-column layout
        columnWidths.push('md:w-' + (i + 2) + '/5');
      } else if (theme.columnCount === 2 && theme.columnDistribution === 'oneThirdLeft') {
        const percent = i === 0 ? 30 : 70;
        columnWidths.push(`md:w-${percent}p`);
      } else if (theme.columnCount === 2 && theme.columnDistribution === 'oneThirdRight') {
        const percent = i === 0 ? 70 : 30;
        columnWidths.push(`md:w-${percent}p`);
      } else if (theme.columnCount === 4) {
        columnWidths.push('w-full md:w-1/2 xl:w-1/4');
      } else if (theme.columnCount > 4) {
        columnWidths.push('w-1/2 md:w-1/3 xl:w-1/6');
      } else {
        columnWidths.push('md:w-1/' + theme.columnCount);
      }
    }
    return columnWidths;
  }

  getLinkColor(theme: ComponentTheme): string {
    //this gets us a list of all classes that start with bg or from, and contain a tailwind color code eg 600. It will return null if there is no match, and an array with the desired string value if there is one.
    let themeClasses = theme.backgroundColor;
    if (theme.styles && theme.styles.length > 0) {
      themeClasses = theme.styles.join(' ');
    }
    if (themeClasses && typeof themeClasses === 'string') {
      const bgColorCodedList = themeClasses.match(/bg.*\d\d\d |from.*\d\d\d /g);
      if (bgColorCodedList && bgColorCodedList.length > 0) {
        //there should only ever be one returned value in the bgColorCodedList array. We will extract the value from this string, and test it.
        const colorValue = parseInt(bgColorCodedList[0].match(/\d\d\d/)[0]);
        if (colorValue && colorValue > 500) {
          return 'text-white';
        }
      }
    }
    return 'text-ace-teal-800';
  }

  getLastClassForType(type: string, styles: string[]): string {
    const styleClassesForType = styles.filter((style) => style.includes(type));
    const lastClass = styleClassesForType[styleClassesForType.length - 1];
    if (lastClass) {
      return ' ' + lastClass;
    }
    if (!lastClass && type === 'text-') {
      return ' text-dark';
    }
    return '';
  }

  getDominantClassFromStyleList(styles: string[]): string {
    /* we have some subsets of styles in the new styles array.
For each subset, we only want to return the last, to ensure correct primacy of classes.
these are 'bg-', 'text-',.
*/
    const bgClass = this.getLastClassForType('bg-', styles);
    const textClass = this.getLastClassForType('text-', styles);
    const defaultStyle = styles
      .filter((item) => {
        if (!/(bg|text|py|pt|pb)/.test(item)) {
          return item;
        }
      })
      .join(' ');
    return defaultStyle + bgClass + textClass;
  }

  getRootClasses(theme: ComponentTheme): string {
    if (theme.styles && theme.styles.length > 0) {
      return this.getDominantClassFromStyleList(theme.styles);
    }
    if (theme.textColor && theme.textColor.length > 0) {
      if (theme.backgroundColor !== null) {
        let rootClass = theme.backgroundColor.replace(
          /text-white|text-ace-grey-900|text-vis-reverse|text-vis-regular/,
          theme.textColor,
        );
        if (!theme.backgroundColor.includes('text')) {
          rootClass += ' ' + theme.textColor;
        }
        return rootClass;
      } else {
        return theme.textColor;
      }
    }
    return theme.backgroundColor;
  }

  getContainerClasses_v3(theme: ComponentTheme): string {
    if (theme.styles && theme.styles.length > 0) {
      return theme.styles
        .filter((item) => /^(m|p)/.test(item))
        .map((item) => `!${item}`)
        .join(' ');
    }
    return '';
  }

  //TODO: Remove after v3 migration
  getContainerLevelClasses(tailwindClasses: string): string {
    if (!tailwindClasses) {
      return '';
    }

    const words = tailwindClasses.split(/\s+/);
    const filteredWords = words.filter(
      (word) => !this.filteredTailwindClasses.some((prefix) => word.startsWith(prefix)),
    );
    const modifiedWords: string[] = filteredWords.map((word) => `!${word}`);

    return modifiedWords.join(' ');
  }

  getTopLevelClasses(tailwindClasses: string) {
    if (!tailwindClasses) {
      return '';
    }

    const words = tailwindClasses.split(/\s+/);
    const filteredWords = words.filter((word) =>
      this.filteredTailwindClasses.some((prefix) => word.startsWith(prefix)),
    );

    return filteredWords.join(' ');
  }
}

export interface ComponentTheme {
  styles?: string[];
  backgroundColor?: string;
  backgroundImage: Image | undefined;
  columnCount?: number;
  textColor: string;
  columnDistribution: string;
}

export interface ThemeInterface {
  root: string;
  container: string;
  columnWidths?: string[];
  columnOrder: string;
  bgImage: string;
  linkColor?: string;
}
