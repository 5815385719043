/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit, HostBinding } from '@angular/core';
import { faQuestionCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash-es';
import type { Header, HeaderNavLink } from './header';
import { headerData } from './header-data';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';

@Component({
  selector: 'aarp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @HostBinding('class.sticky-header') headerSticky = false;
  showHeader = true;
  faQuestionCircle = faQuestionCircle;
  faUserCircle = faUserCircle;
  showMenu = false;
  resolverData: PageDataResponse;
  hideNav = false;
  headerData: Header = headerData;
  subscription: Subscription;
  currentUrl: string;

  constructor(private route: ActivatedRoute) {
    if (this.route.snapshot) {
      this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    }
  }

  ngOnInit() {
    if (this.resolverData.craftData?.headerData) {
      this.hideNav = this.resolverData.craftData.headerData.hideNav;
      this.headerSticky = !this.resolverData.craftData.headerData.hideStickyBanner;
    }
    if (this.hideNav) {
      this.showHeader = false;
    }
    this.currentUrl = `/${this.route.snapshot.url.join('/')}`;
  }

  get mainHeaderLinks(): HeaderNavLink[] {
    return this.headerData.aarpMainLinks;
  }

  get loginLink(): HeaderNavLink {
    return this.headerData.aarpLoginLink;
  }

  get helpLink(): HeaderNavLink {
    return this.headerData.aarpHelpLink;
  }

  get registerLink(): HeaderNavLink {
    return this.headerData.aarpRegisterLink;
  }
}
