<div *ngIf="showPracticeTest" [style]="theme?.bgImage" [className]="theme?.root + ' bg-no-repeat bg-center bg-cover'">
  <div class="container">
    <h2 class="py-v-spacing tab:px-v-spacing max-w-vision mx-auto" id="h2__title">{{ title }}</h2>
    <div id="div__subtitle" class="py-v-spacing tab:px-v-spacing max-w-vision mx-auto">{{ subTitle }}</div>
    <div
      class="
        w-full
        rounded rounded-full
        h-5
        py-v-spacing
        tab:px-v-spacing
        max-w-vision
        mx-auto
        bg-ace-grey-400
        text-vis-regular
        relative
      "
    >
      <div
        [className]="'rounded rounded-full h-5 w-1/2 text-center align-middle ' + contrastColorClass"
        [style]="getWidthPercentage"
      ></div>
      <p class="text-xs absolute top-0 left-1/2 transform -translate-x-1/2">{{ qMarker }}</p>
    </div>
    <div class="w-full rounded rounded-xs mt-v-spacing py-v-spacing tab:px-v-spacing max-w-vision mx-auto">
      <div *ngIf="q" class="w-full bg-white text-vis-regular py-v-spacing sm:px-v-spacing">
        <div *ngIf="q && !q.isAnswer">
          <p class="text-ace-teal-400 text font-medium" id="question__number">Question {{ q.id }}:</p>
          <h3 class="text-vis-regular" id="question__title">{{ q.question }}</h3>
          <ul>
            <ng-template ngFor let-a [ngForOf]="q.answers">
              <li class="p-1 w-full text-base font-thin hover:bg-ace-grey-400">
                <label class="w-full flex cursor-pointer">
                  <input
                    type="radio"
                    name="answers"
                    value="{{ a.id }}"
                    class="h-4 w-4 mr-v-spacing mt-1"
                    id="{{ a.id }}"
                    (change)="selectAnswerChange(a.id)"
                  />
                  <p id="question__title__{{ a.id }}" class="w-full">{{ a.text }}</p>
                </label>
              </li>
            </ng-template>
          </ul>
          <button
            *ngIf="selectedAnswer !== 0"
            [className]="
              'mt-v-spacing h-8 p-2 cursor-pointer inline-flex items-center justify-center rounded-full text-vis-reverse ' +
              contrastColorClassWithHover
            "
            (click)="checkAnswer(q)"
          >
            Check Answer
          </button>
        </div>
        <div class="text-center w-full" *ngIf="q && q.isAnswer">
          <p
            class="text-lg mb-v-spacing"
            [ngClass]="{ 'text-ace-teal-400': currentQuestionResult, 'text-red-400': !currentQuestionResult }"
          >
            {{ currentQuestionResultText }}
          </p>
          <p class="text-sm text-vis-regular font-medium mb-v-spacing">Explanation</p>
          <p class="text-sm text-vis-regular mb-v-spacing">{{ q.explanation }}</p>
          <button
            [className]="
              'mt-v-spacing h-8 p-2 cursor-pointer inline-flex items-center justify-center rounded-full text-vis-reverse ' +
              contrastColorClassWithHover
            "
            (click)="nextQuestion()"
          >
            {{ this.questions.length === this.currentQuestion + 1 ? 'See Results' : 'Next Question' }}
          </button>
        </div>
      </div>
      <div *ngIf="!q">
        <div class="text-center w-full p-v-spacing bg-white">
          <p class="text-sm text-vis-regular mb-v-spacing">
            You scored {{ this.correctAnwsersCount }} out of {{ this.questions.length }} questions correctly! Let
            Aceable help you prepare and Ace your exam to jumpstart your new career!
          </p>
          <p class="text-sm text-vis-regular mb-v-spacing">No worries! We can help you ace your exam in no time!</p>
          <p class="text-sm text-vis-regular font-medium mb-v-spacing">Get your Real Estate License Course Today!</p>
          <a
            [className]="
              'h-8 p-v-spacing cursor-pointer inline-flex items-center justify-center rounded-full text-vis-reverse ' +
              contrastColorClassWithHover
            "
            href="{{ resultButtonAceProps.link }}"
            [attr.target]="resultButtonAceProps.newTab ? '_blank' : ''"
            [attr.rel]="
              resultButtonAceProps.newTab && resultButtonAceProps.nofollow
                ? 'nofollow noopener noreferrer'
                : resultButtonAceProps.newTab && !resultButtonAceProps.nofollow
                ? 'noopener noreferrer'
                : resultButtonAceProps.nofollow
                ? 'nofollow'
                : ''
            "
          >
            {{ resultButtonAceProps.label }}
          </a>
        </div>
        <div class="text-center w-full py-v-spacing sm:px-v-spacing bg-white">
          <p class="text-sm text-vis-regular mb-v-spacing">
            Need more practice and interactive study guides to help prepare for the actual real estate exam? Check out
            PrepAgent and their study guides to help you in your state!
          </p>
          <a
            [className]="
              'h-8 py-v-spacing sm:px-v-spacing cursor-pointer inline-flex items-center justify-center rounded-full text-vis-reverse ' +
              contrastColorClassWithHover
            "
            href="{{ resultButtonPrepAgentProps.link }}"
            [attr.target]="resultButtonPrepAgentProps.newTab ? '_blank' : ''"
            [attr.rel]="
              resultButtonPrepAgentProps.newTab && resultButtonPrepAgentProps.nofollow
                ? 'nofollow noopener noreferrer'
                : resultButtonPrepAgentProps.newTab && !resultButtonPrepAgentProps.nofollow
                ? 'noopener noreferrer'
                : resultButtonPrepAgentProps.nofollow
                ? 'nofollow'
                : ''
            "
          >
            {{ resultButtonPrepAgentProps.label }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
