<div *ngIf="theme" class="font-body bg-cover multi-column {{ theme?.root }} {{topLevelClasses}}" [style]="theme?.bgImage">
  <div class="{{containerLevelClasses}} container {{ theme?.container }}" #multicolumn>
    <div
      *ngIf="data.componentTitle !== null && data.componentTitle.length !== 0"
      class="py-v-spacing tab:px-v-spacing max-w-vision mx-auto wysiwyg multi-column__title"
      [innerHTML]="data.componentTitle"
    ></div>
    <div class="flex flex-wrap xl:flex-nowrap xl:flex-row justify-center" [class.sm-flex-column]="data.columnList.length < 5">
      <div
        *ngFor="let column of data.columnList; let index = index"
        class="multi-column__column py-v-spacing w-full {{ theme?.columnWidths[index] }}"
        [ngClass]="{
          'tab:px-v-spacing max-w-vision mx-auto': data.columnList.length === 1,
          'sm:px-v-spacing': data.columnList.length > 1
        }"
      >
        <div class="wysiwyg" [innerHTML]="column.columnContent"></div>

        <!--Optional dropdown-->
        <div *ngIf="column.dropdownTitle && column.dropdown.length > 0" class="py-v-spacing sm:px-v-spacing">
          <vision-dropdown [dropdownData]="setDropdownData(column.dropdown, column.dropdownTitle, column.dropdownAlignment)"></vision-dropdown>
        </div>
      </div>
    </div>
    <div
    *ngIf="showModal"
    class="multi-column__modal fixed inset-0 bg-vis-dark/50 z-50 flex justify-center items-center"
  >
    <div
      class="
        multi-column__modal-body
        bg-white
        text-vis-regular
        shadow-md
        px-8
        py-v-spacing
        wysiwyg
        relative
        rounded
        flex
      "
    >
      <div class="multi-column__modal-body-content wysiwyg" [innerHTML]="modalContent"></div>
      <fa-icon
        class="multi-column__modal-close text-vis-muted absolute right-v-spacing top-v-spacing fa-1x"
        (click)="showModal = false"
        [icon]="faTimes"
      ></fa-icon>
    </div>
  </div>
  </div>
</div>
