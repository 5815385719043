import type { OnInit } from '@angular/core';
import { Component, HostListener, Input } from '@angular/core';
import { faCaretDown, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash-es';

import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { EnvService } from '@core-mkt/services/env/env.service';
import { GeolocationService } from '@core-mkt/services/geolocation/geolocation.service';
import type { ProductSection } from '@core-mkt/shared/components/product-card/product-card/product-card';
import type { PriceSelectorItem } from './price-selector';
import { PriceSelector } from './price-selector';

@Component({
  selector: 'price-filter',
  templateUrl: './price-selector.component.html',
  styleUrls: ['./price-selector.component.scss'],
})
export class PriceSelectorComponent implements OnInit {
  @Input() data: PriceSelector;
  resolverData: PageDataResponse;
  faCaretDown = faCaretDown;
  faChevronDown = faChevronDown;
  dropdownCollapse = false;
  selectedItem: PriceSelectorItem;
  selectorInstruction = 'Filter information by state';
  selectorCardTitle = '<h2>AARP Smart Driver Course Pricing</h2>';
  selectorCardCopy =
    '<p class="small font-extrabold">${this.finalPrice} for AARP members</p>\n<p class="small font-extrabold">${this.crossedOutPrice} for non-members</p>\n<p class="eyebrow">Money-Back Guarantee!</p>';
  selectorList: PriceSelectorItem[];
  selectorProductCard: ProductSection = {
    styles: ['bg-white text-vis-regular'],
    productCardList: [
      {
        cardBackgroundColor: 'bg-white text-vis-regular',
        cardTextColor: 'text-vis-regular',
        cardTitle: this.selectorCardTitle,
        ctaLabel: 'Register Now',
        ctaLink: null,
        productCtaTag: 'DRIVER-SAFETY-BTN-CLK-PRICING-GET_STARTED',
        ctaNofollow: false,
        ctaNewTab: false,
        learnMoreLabel: null,
        learnMoreLink: null,
        learnMoreNewTab: false,
        learnMoreNofollow: false,
        productEntry: [
          {
            productPageUrl: null,
            productState: 'AL',
            xgritBundleCoupon: null,
            xgritCoupon: null,
            xgritProductDisableRsa: false,
            xgritProductId: 'W81twjzQXDW6Fw8r',
          },
        ],
        showSavings: true,
        starCaption: null,
        starRating: '',
        textContent: this.selectorCardCopy,
      },
    ],
    backgroundColor: 'bg-white text-vis-regular',
    backgroundImage: [],
    hideCta: false,
    ctaStyle: 'primary',
    sectionTitle: null,
    textColor: '',
    typeHandle: 'aarpProductCards',
    useCompactCards: false,
  };
  showProductCard = false;
  // Loading state for geolocation
  public isLoadingState: boolean;
  // Selected state for geolocation
  public selectedState = '';

  get dropdownPlaceholder(): string {
    return this.selectedItem?.state || 'Alabama';
  }

  get cardTitle(): string {
    if (this.selectorCardTitle.includes('${state}')) {
      return this.selectorCardTitle.replace('${state}', this.selectedItem?.state);
    }
    return `<h2>${this.selectedItem?.state} AARP Smart Driver Course Pricing</h2>`;
  }

  constructor(public geolocation: GeolocationService, private envService: EnvService) {
    this.geolocation?.isLoading.subscribe((isLoading) => {
      this.isLoadingState = isLoading;
    });
  }

  ngOnInit(): void {
    this.selectorList = cloneDeep(this.data.selectorList);
    this.selectedItem = this.selectorList[0];
    this.selectorInstruction = this.data.selectorInstructions;
    this.selectorCardCopy = this.data.cardText;
    this.selectorCardTitle = this.data.cardTitle;
    this.showProductCard = true;

    this.geolocation.stateAbbr.subscribe((stateAbbr) => {
      this.selectedState = this.geolocation.getStateName(stateAbbr);
      this.selectedItem =
        typeof stateAbbr === 'undefined'
          ? this.selectorList[0]
          : this.selectorList.find((item) => item.state === this.selectedState);

      this.updateProductCard();
    });
  }

  updateProduct(filterItem: PriceSelectorItem): void {
    this.dropdownCollapse = false;
    this.selectedItem = filterItem;
    this.updateProductCard();
    // Update the cookie value if user selects a different state
    this.geolocation.updateLocationCookie(this.geolocation.getStateAbbr(filterItem.state));
  }

  updateProductMobile(index: string): void {
    const filterItem = this.selectorList[parseInt(index)];
    this.dropdownCollapse = false;
    this.selectedItem = filterItem;
    this.updateProductCard();
    // Update the cookie value if user selects a different state
    this.geolocation.updateLocationCookie(this.geolocation.getStateAbbr(filterItem.state));
  }

  updateProductCard(): void {
    const updatedProductCard = cloneDeep(this.selectorProductCard);
    if (this.selectedItem && this.selectedItem.product) {
      updatedProductCard.productCardList[0].productEntry = this.selectedItem.product;

      updatedProductCard.productCardList[0].productEntry[0].disclaimer = this.selectedItem.disclaimer;
      if (this.envService.get.xgritRedirectUrl) {
        updatedProductCard.productCardList[0].ctaLink = this.envService.get.xgritRedirectUrl;
      }
      updatedProductCard.productCardList[0].textContent = this.selectorCardCopy;
      updatedProductCard.productCardList[0].cardTitle = this.cardTitle;
      this.selectorProductCard = updatedProductCard;
    }
  }

  toggleDropdown(event: MouseEvent): void {
    event.stopPropagation();
    this.dropdownCollapse = !this.dropdownCollapse;
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(): void {
    if (this.dropdownCollapse) {
      this.dropdownCollapse = false;
    }
  }
}
