import { AaCraftQueries } from '@core-mkt/brands/aa/src/app/craft/craft-queries';
import { AarpCraftQueries } from '@core-mkt/brands/aarp/src/app/craft/craft-queries';
import { AceCraftQueries } from '@core-mkt/brands/ace/src/app/craft/craft-queries';
import { SingleTemplateCraftQueries } from '@core-mkt/shared/craft/craft-queries';
import type { CraftSiteTemplateMapping } from './craft-site-template-mapping';

export const CraftQueries: CraftSiteTemplateMapping[] = [
  {
    site: 'ACE',
    queries: AceCraftQueries,
  },
  {
    site: 'AA',
    queries: AaCraftQueries,
  },
  {
    site: 'AARP',
    queries: AarpCraftQueries,
  },
  {
    site: 'DEC',
    queries: SingleTemplateCraftQueries,
  },
  {
    site: 'INS',
    queries: SingleTemplateCraftQueries,
  },
  {
    site: 'MTG',
    queries: SingleTemplateCraftQueries,
  },
];
