import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import type { CraftXGritProductEntry } from '@core-mkt/interfaces/craft-xgrit-product-entry';
import { XGritApiService } from '@core-mkt/services/xgrit-api/xgrit-api.service';
import type { XGritApiData, XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type {
  ProductCodeLists,
  XGritPurchaseReceiptPriceResponse,
  XgritPurchaseReceiptMultipriceResponse,
} from '@core-mkt/services/xgrit-api/xgrit-purchase-receipt-price-response';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    readonly httpClient: HttpClient,
    private xas: XGritApiService,
    private route: ActivatedRoute,
    private router: Router,
    private serverState: TransferState,
  ) {}

  private data = new BehaviorSubject(null);
  currentData = this.data.asObservable();

  private setStateTransfer = (productId: CraftXGritProductEntry, type: string, state: any) => {
    this.serverState.remove(makeStateKey(`${type}_${productId.xgritProductId}_${productId.xgritCoupon}`));
    this.serverState.set(makeStateKey(`${type}_${productId.xgritProductId}_${productId.xgritCoupon}`), state);
  };

  private getStateTransfer = (productId: CraftXGritProductEntry, type: string) => {
    const falback = {
      checkoutLink: '',
      discountPrice: 0,
      originalPrice: 0,
      discountType: 'PERCENT',
      maxPrice: 0,
      type: '',
      filterType: '',
      title: '',
      app: '',
    };
    console.log(`Using state transfer ${type} for product/coupon ${productId.xgritProductId}/${productId.xgritCoupon}`);
    return this.serverState.get(<any>`${type}_${productId.xgritProductId}_${productId.xgritCoupon}`, falback);
  };

  /**
   * queryProductEntry is used to get product API data from an XGritProductEntry.
   *
   * @param {CraftXGritProductEntry} productEntry - The product entry from Craft that you would like to get API data for
   * @return {Promise<PurchaseReceiptPriceResponse>} Returns a promise containing the product data returned by the xGrit API's 'purchase-receipt/price' endpoint
   *
   * @example
   *     queryProductEntry(productEntry)
   */
  private async queryProductEntry(productEntry: CraftXGritProductEntry): Promise<XGritPurchaseReceiptPriceResponse> {
    const productPriceInfoResponse = await this.xas.getProductPriceInfo(
      productEntry.xgritProductId,
      productEntry.xgritCoupon,
    );
    const productApiData = lastValueFrom(productPriceInfoResponse);

    const liList = (await productApiData).lineItemList;
    if (liList == undefined || liList.length == 0) {
      return Promise.reject(`no product Id or course id returned for ${productEntry.xgritProductId}`);
    }

    return productApiData;
  }

  private async queryMultiProductEntries(
    multiProductEntries: CraftXGritProductEntry[],
  ): Promise<XgritPurchaseReceiptMultipriceResponse> {
    const priceSetList: ProductCodeLists[] = [];

    multiProductEntries.forEach((productEntry) => {
      priceSetList.push({
        productIdList: [productEntry.xgritProductId],
        ...(productEntry.xgritCoupon && { couponCodeList: [productEntry.xgritCoupon] }),
      });
    });

    const multiProductPriceInfoResponse = await this.xas.getMultiProductPriceInfo(priceSetList);
    const multiProductApiData = lastValueFrom(multiProductPriceInfoResponse);

    const liList = (await multiProductApiData).priceSetList;
    if (liList == undefined || liList.length == 0) {
      return Promise.reject(`no products Id or courses id returned for ${multiProductEntries}`);
    }

    return multiProductApiData;
  }

  /**
   * constructProductData is used to turn product API data of type PurchaseReceiptPriceResponse and a product entry of type XGritProductEntry into a product data instance of type XGritProductData.
   *
   * @param {PurchaseReceiptPriceResponse} productApiData - Product API data
   * @param {CraftXGritProductEntry} productEntry - Product entry from Craft
   * @return {XGritApiData} - Returns API data for the product
   *
   * @example
   *     constructProductData(productApiData, productEntry)
   */
  private constructProductData(
    productApiData: XGritPurchaseReceiptPriceResponse,
    productEntry: CraftXGritProductEntry,
  ): XGritApiData {
    const xgritProductId = productApiData.lineItemList[0].product._id;
    const xgritCourseId = productApiData.lineItemList[0].product.courseId;
    const xgritCoupon = productEntry.xgritCoupon;
    const xgritBundleCoupon = productEntry.xgritBundleCoupon;
    const xgritDisableRsa = productEntry.xgritProductDisableRsa;
    const productState = productEntry.productState ? productEntry.productState : undefined;
    const appType = productApiData.lineItemList[0].product.filter.app;

    const xgritCheckoutUrl = this.xas.getCheckoutUrl(
      xgritProductId,
      xgritCourseId,
      appType,
      xgritDisableRsa,
      xgritCoupon,
      productState,
      xgritBundleCoupon,
    );

    const apiData: XGritApiData = {
      originalPrice: productApiData.grossTotal,
      discountPrice: productApiData.total,
      discountType: this.searchForDiscountType(productApiData),
      maxPrice: productApiData.lineItemList[0].product.pricing.max,
      checkoutLink: xgritCheckoutUrl,
      type: productApiData.lineItemList[0].product.type,
      filterType: productApiData.lineItemList[0].product.filter.type,
      title: productApiData.lineItemList[0].title,
      app: productApiData.lineItemList[0].product.filter.app,
    };

    return apiData;
  }

  searchForDiscountType(productApiData): string {
    const appliedCoupon = productApiData.lineItemList[0].displayCouponList.find((coupon) => {
      return coupon.discountAmount === productApiData.grossDiscount;
    });
    return appliedCoupon ? appliedCoupon.discountType : 'PERCENT';
  }

  //sets product to be read by sibling components
  setProduct(product: XGritCompleteProduct): void {
    this.data.next(product);
    this.data.next(new Object());
  }

  //testing to ensure that product entry is valid
  isProductValid(productEntry: CraftXGritProductEntry | CraftXGritProductEntry[]): boolean {
    if (Array.isArray(productEntry)) {
      return productEntry.length > 0 && productEntry[0].xgritProductId !== null;
    }
    return productEntry !== undefined && productEntry !== null && productEntry.xgritProductId !== null;
  }

  //returning product whether or not it is in an array
  extractProduct(productEntry: CraftXGritProductEntry | CraftXGritProductEntry[]): CraftXGritProductEntry {
    if (Array.isArray(productEntry)) {
      return productEntry[0];
    }
    return productEntry;
  }

  /**
   * getProductData returns a "complete" xGrit product that combines the pricing data from the API and the CMS data into a single object (as a Promise).
   *
   * @param {CraftXGritProductEntry} productEntry - Product entry from Craft
   * @return {Promise<XGritCompleteProduct>} - Returns an object containing the original craftData from the CMS as well as data retrieved from the API
   *
   * @example
   *     constructProductData(productEntry)
   */
  async getProductData(productEntry: CraftXGritProductEntry): Promise<XGritCompleteProduct> {
    let data: XGritApiData;
    const url: string = this.router.url;
    const params = this.route.snapshot.queryParams;
    const productEntryToReturn = {
      craftData: productEntry,
      apiData: data,
    };
    let productApiError;
    const productApiData = await this.queryProductEntry(productEntry).catch((err) => {
      productApiError = err;
    });

    // If there was no API request error, pull out the xgrit API, else use State Transfer.
    if (!productApiError) {
      if (productApiData) {
        data = this.constructProductData(productApiData, productEntry);
        productEntryToReturn.apiData = data;

        try {
          this.setStateTransfer(productEntry, 'productEntryApiData', data);
        } catch {
          console.error('State Transfer failed');
        }
      }
    } else {
      //console.error(productEntry, params, url, productApiError);
      productEntryToReturn.apiData = this.getStateTransfer(productEntry, 'productEntryApiData');
    }

    return productEntryToReturn;
  }

  async getMultiProductData(productEntries: CraftXGritProductEntry[]): Promise<XGritCompleteProduct[]> {
    let multiProductApiError;
    const multiProductDataToReturn: XGritCompleteProduct[] = [];
    const multiProductApiData = await this.queryMultiProductEntries(productEntries).catch((err) => {
      multiProductApiError = err;
    });

    if (!multiProductApiError && multiProductApiData) {
      multiProductApiData.priceSetList.forEach((productApiData, index) => {
        if (productApiData.lineItemList) {
          const data = this.constructProductData(productApiData, productEntries[index]);
          const productEntryToReturn = {
            craftData: productEntries[index],
            apiData: data,
          };
          multiProductDataToReturn.push(productEntryToReturn);

          try {
            this.setStateTransfer(productEntries[index], 'productEntryApiData', data);
          } catch {
            console.error('State Transfer failed');
          }
        }
      });
    } else {
      productEntries.forEach((productEntry) => {
        const productEntryToReturn = {
          craftData: productEntry,
          apiData: this.getStateTransfer(productEntry, 'productEntryApiData'),
        };
        multiProductDataToReturn.push(productEntryToReturn);
      });
    }
    return multiProductDataToReturn;
  }
}
