<div class="border-ace-grey-400 border-b" *ngIf="showDefaultNav">
<header class="font-body container bg-white relative !p-0 {{ activateMobileMenu ? '!fixed h-full !max-w-full w-full top-0 z-50 overflow-x-hidden' : '' }}">
  <div [class.border-b]="activateMobileMenu">
  <div class="header-wrapper flex-wrap flex align-top !px-6 {{ activateMobileMenu ? 'container !py-0' : '' }}">
    <!-- Aceable logo -->
    <a href="/" class="mr-auto lg:mr-6 py-5">
      <img
        class="ace__nav-logo max-h-10"
        src="{{ headerLogo }}"
        width="175"
        height="38px"
        alt="Aceable Logo"
      />
    </a>
    <!-- Default Nav -->
    <nav class="ace__nav hidden lg:flex flex-grow align-middle">
      <ace-header-navlink *ngFor="let navLink of headerData" [data]="navLink" class="flex items-center first:ml-0 mx-3">
      </ace-header-navlink>
    </nav>
    <!-- My Account Dropdown -->
    <nav class="ace__nav hidden lg:flex align-middle">
      <ace-header-navlink *ngFor="let navLink of myAccountData" [data]="navLink"
        class="relative flex items-center my-account">
      </ace-header-navlink>
    </nav>
    <!-- Mobile Hamburger -->
    <button
      (click)="toggleMobileMenu()"
      class="
        text-ace-teal-800
        focus:outline-none
        font-body
        flex
        lg:hidden
        items-center
        self-center
      "
    >
    <img 
      [attr.loading]="'eager'" 
      width="32px" 
      height="32px" 
      class="w-8 h-8" 
      [src]="activateMobileMenu ? 'https://xgrit-ecom.imgix.net/ace/X.svg?v=1712674314' : 'https://xgrit-ecom.imgix.net/ace/Hamburger-Menu.svg?v=1712674404'"
      [alt]="activateMobileMenu ? 'Close Menu' : 'Menu icon'"
      />
    </button>
  </div>
  </div>
  <!-- Mobile Nav Menu -->
  <div
  *ngIf="activateMobileMenu"
  class="ace__nav-mobile bg-white flex flex-col justify-items-start content-start lg:hidden relative"
  >
    <div class="bg-white">
      <collapse-link *ngFor="let link of headerData" [link]="link"></collapse-link>
      <collapse-link *ngFor="let link of myAccountData" [link]="link"></collapse-link>
    </div>
    <a
      href="https://driving.aceable.com/user/signin"
      class="
        m-6
        ace__nav-sign-up
        secondary-button
        px-12
        py-5
        w-fit
      "
    >
      Sign In
    </a>
  </div>
</header>
</div>

