<div *ngIf="showCalculator" [className]="theme?.root + ' bg-no-repeat bg-center bg-cover'" [style]="theme?.bgImage">
  <div class="container">
    <div class="flex flex-col md:flex-row">
      <div class="w-full py-v-spacing sm:px-v-spacing wysiwyg md:mr-v-spacing">
        <div [innerHTML]="sideContent"></div>
      </div>
      <div class="w-full py-v-spacing sm:px-v-spacing text-vis-regular bg-white shadow-lg rounded-md">
        <div class="p-4 flex flex-col">
          <div class="w-full text-center text-lg leading-7 font-bold">Commission Calculator</div>
          <form [formGroup]="calculatorForm">
            <div class="py-2 flex flex-col md:flex-row">
              <div class="w-full py-2 md:pr-1">
                <p class="text-xs font-bold pb-2">Zip Code</p>
                <input
                  formControlName="zip"
                  class="w-full px-3 py-4 bg-white text-vis-subtle rounded-md"
                  [ngClass]="{ 'border border-red-400': showZipError, 'calculator-border': !showZipError }"
                  type="text"
                  inputmode="decimal"
                  autocomplete="postal-code"
                  placeholder="Zip Code"
                  (keyup)="formChange()"
                />
                <p *ngIf="showZipError" class="error-message text-red-400">Zip code must be five numbers</p>
              </div>
              <div class="w-full py-2 md:pl-1">
                <p class="text-xs font-bold pb-2">Commission Percentage</p>
                <div>
                  <input
                    formControlName="percentage"
                    class="w-full px-3 py-4 bg-white text-vis-subtle rounded-md"
                    [ngClass]="{
                      'border border-red-400': showPercentageError,
                      'calculator-border': !showPercentageError
                    }"
                    type="text"
                    inputmode="decimal"
                    autocomplete="off"
                    placeholder="Commission Percentage"
                    (keyup)="formChange()"
                  />
                  <fa-icon
                    class="absolute font-bold py-4 items-center transform -translate-x-5"
                    [icon]="faPercentege"
                  ></fa-icon>
                </div>
                <p *ngIf="showPercentageError" class="error-message text-red-400">Must be a number between 0 and 100</p>
              </div>
            </div>
          </form>
          <div class="w-full pt-2 pb-8">
            <p class="text-xs font-bold pb-2">Targeted Number of Commissions</p>
            <div class="flex flex-row">
              <div
                class="mx-1 w-full p-1 md:p-3 bg-white rounded-md text-center cursor-pointer"
                [ngClass]="{
                  'selected-calculator-border ': selectedComission === 5,
                  'calculator-border': selectedComission !== 5
                }"
                (click)="formChange(5)"
              >
                <p class="w-full text-sm font-semibold">5</p>
                <p class="w-full text-xs text-vis-subtle">Commissions</p>
              </div>
              <div
                class="mx-1 w-full p-1 md:p-3 bg-white rounded-md text-center cursor-pointer"
                [ngClass]="{
                  'selected-calculator-border ': selectedComission === 10,
                  'calculator-border': selectedComission !== 10
                }"
                (click)="formChange(10)"
              >
                <p class="w-full text-sm font-semibold">10</p>
                <p class="w-full text-xs text-vis-subtle">Commissions</p>
              </div>
              <div
                class="mx-1 w-full p-1 md:p-3 bg-white rounded-md text-center cursor-pointer"
                [ngClass]="{
                  'selected-calculator-border ': selectedComission === 15,
                  'calculator-border': selectedComission !== 15
                }"
                (click)="formChange(15)"
              >
                <p class="w-full text-sm font-semibold">15</p>
                <p class="w-full text-xs text-vis-subtle">Commissions</p>
              </div>
            </div>
          </div>
          <div class="w-full calculator-border"></div>
          <div class="w-full pt-b pt-8">
            <p class="text-xs font-bold w-full">Estimated Commission*</p>
            <p class="text-lg font-bold w-full">{{ commission }}</p>
            <div *ngIf="showNoDataError" class="w-full rounded-md error-message bg-ace-teal-200 p-3">
              <p>
                <fa-icon class="pr-3 info-message-icon" [icon]="faInfoCircle"></fa-icon>Uh oh, we don't have any home
                data for {{ zip }}.
              </p>
            </div>
            <p class="text-xs w-full text-vis-subtle">
              *Commission revenue shown is an average calculated from data presented by Zillow.com. The information
              above is not a guaranteed or suggested income or commission rate for potential or current agents in the
              listed zip code.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
