import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { PricingService } from '@core-mkt/services/pricing/pricing.service';
import { ProductService } from '@core-mkt/services/product/product.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import type { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import { StandAloneCta } from './stand-alone-cta';

@Component({
  selector: 'stand-alone-cta',
  templateUrl: './stand-alone-cta.component.html',
  styleUrls: ['./stand-alone-cta.scss'],
})
export class StandAloneCtaComponent implements OnInit {
  @Input() data: StandAloneCta;
  product: XGritCompleteProduct;
  productCtaLink: string;
  originalPrice: number;
  discountPrice: number;
  theme: ThemeInterface;
  pricingText: string;

  constructor(private ps: ProductService, private themeParser: ThemeParserService, private pricing: PricingService) {}

  get ctaType(): string {
    return this.data.ctaType !== null
      ? this.data.ctaType
      : 'bg-ace-blue-600 hover:bg-ace-blue-500 text-vis-reverse h-12 inline-flex items-center justify-center';
  }

  get displayPrice(): boolean {
    return this.data.displayPrice && this.product !== undefined;
  }

  get ctaButtonData(): VisionButton {
    return {
      buttonStyle: this.data.ctaType,
      bgTheme: this.data.backgroundColor,
      buttonText: this.ctaLabel,
      buttonLink: this.customCtaLink,
      fullWidth: false,
      nofollow: this.ctaNofollow,
      noreferrer: this.ctaNewTab,
      noopener: this.ctaNewTab,
      ctaTag: this.data.ctaTag,
      newTab: this.ctaNewTab,
    };
  }

  get ctaLabel(): string {
    return this.data.ctaLabel !== null ? this.data.ctaLabel : 'Start Today';
  }

  get customCtaLink(): string {
    return this.data.ctaLink !== null ? this.data.ctaLink : this.productCtaLink;
  }

  get ctaNewTab(): boolean {
    return this.data.ctaNewTab;
  }

  get ctaNofollow(): boolean {
    return this.data.ctaNofollow;
  }

  ngOnInit(): void {
    if (this.ps.isProductValid(this.data.product)) {
      const entry = this.ps.extractProduct(this.data.product);
      this.ps.getProductData(entry).then((product: XGritCompleteProduct) => {
        this.product = product;
        this.productCtaLink = product.apiData.checkoutLink;
        this.originalPrice = product.apiData.originalPrice;
        this.discountPrice = product.apiData.discountPrice;
        this.pricingText = this.pricing.getPricingText(this.product, this.data.pricingText);
      });
    }

    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: null,
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };

    this.theme = this.themeParser.getThemeClasses(themeInput);
  }
}
