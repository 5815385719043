import type { HeaderNavLink } from './header';

export const headerData: HeaderNavLink[] = [
  {
    linkUrl: '#',
    linkLabel: 'Drivers Ed',
    isDropDown: true,
    menuSize: '340px',
    ddList: [
      {
        linkUrl: '/drivers-ed/california/',
        linkLabel: '<strong>California</strong> Drivers Ed',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/drivers-ed/california/knowledge-test-prep-course/',
        linkLabel: '<strong>California</strong> Knowledge Test Prep Course',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/drivers-ed/florida/',
        linkLabel: '<strong>Florida</strong> Drivers Ed',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/drivers-ed/georgia/',
        linkLabel: '<strong>Georgia</strong> Drivers Ed',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/drivers-ed/illinois/',
        linkLabel: '<strong>Illinois</strong> Adult Drivers Ed',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/drivers-ed/nevada/',
        linkLabel: '<strong>Nevada</strong> Drivers Ed',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/drivers-ed/ohio/',
        linkLabel: '<strong>Ohio</strong> Drivers Ed',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/drivers-ed/oklahoma/',
        linkLabel: '<strong>Oklahoma</strong> Parent-Taught Drivers Ed',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/drivers-ed/texas-parent-taught/',
        linkLabel: '<strong>Texas</strong> Parent-Taught Drivers Ed',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/drivers-ed/texas-adult/',
        linkLabel: '<strong>Texas</strong> Adult Drivers Ed',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/drivers-ed/texas-learners-permit-test-study-guide/',
        linkLabel: '<strong>Texas</strong> Permit Test Prep',
        newTab: false,
        nofollow: false,
      },
    ],
  },
  {
    linkUrl: '#',
    linkLabel: 'Defensive Driving',
    menuSize: '340px',
    isDropDown: true,
    ddList: [
      {
        linkUrl: '/traffic-school/california/',
        linkLabel: '<strong>California</strong> Traffic School',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/driver-improvement/florida/',
        linkLabel: '<strong>Florida</strong> Basic Driver Improvement',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/defensive-driving/texas/',
        linkLabel: '<strong>Texas</strong> Defensive Driving',
        newTab: false,
        nofollow: false,
      },
    ],
  },
  {
    linkUrl: '#',
    linkLabel: 'Driving Resources',
    menuSize: '340px',
    isDropDown: true,
    ddList: [
      {
        linkUrl: '/dmv/',
        linkLabel: 'DMV Information',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/safe-driving/',
        linkLabel: 'Safe Driving Tips',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/blog/',
        linkLabel: 'Driving Articles',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/roadside/',
        linkLabel: 'Allstate Roadside Assistance',
        newTab: false,
        nofollow: false,
      },
    ],
  },
  {
    linkUrl: '#',
    linkLabel: 'Our Company',
    menuSize: '340px',
    isDropDown: true,
    ddList: [
      {
        linkUrl: '/company/',
        linkLabel: 'Our Mission and Values',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/company/aceable-brands/',
        linkLabel: 'Aceable Brands',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/company/why-choose-aceable/',
        linkLabel: 'Why Choose Us',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/driving-partnerships/',
        linkLabel: 'Partnerships',
        newTab: false,
        nofollow: false,
      },
    ],
  },
];

export const myAccountData: HeaderNavLink[] = [
  {
    linkUrl: '#',
    linkLabel: 'My Account',
    menuSize: '180px',
    isDropDown: true,
    ddList: [
      {
        linkUrl: 'https://driving.aceable.com/user/signin',
        linkLabel: 'Sign In',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: 'https://support.aceabledriving.com/hc/en-us/',
        linkLabel: 'Help Center',
        newTab: false,
        nofollow: false,
      },
    ],
  },
];
