import { InsertableModule } from '@aceable/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterModule as AAFooterModule } from '@core-mkt/aa/src/app/components/footer/footer.module';
import { MarketingModule } from '@core-mkt/shared/components/marketing/marketing.module';
import { AccordionModule } from '../../components/accordion/accordion.module';
import { BreadcrumbModule } from '../../components/breadcrumb/breadcrumb.module';
import { CalculatorModule } from '../../components/calculator/calculator.module';
import { EmailFormModule } from '../../components/email-form/email-form.module';
import { FeefoModule } from '../../components/feefo/feefo.module';
import { LayoutModule } from '../../components/layout/layout.module';
import { MultiCardModule } from '../../components/multi-card/multi-card.module';
import { MultiColumnModule } from '../../components/multi-column/multi-column.module';
import { ResourcesModule } from '../../components/resources/resources.module';
import { ReviewsModule } from '../../components/reviews/reviews.module';
import { RsaProductModule } from '../../components/rsa-product/rsa-product.module';
import { RsaModule } from '../../components/rsa/rsa.module';
import { SliderCardModule } from '../../components/slider-card/slider-card.module';
import { SliderModule } from '../../components/slider/slider.module';
import { StandAloneCtaModule } from '../../components/stand-alone-cta/stand-alone-cta.module';
import { TabContentModule } from '../../components/tabbed-content/tabbed-content.module';
import { TopProductModule } from '../../components/top-product/top-product.module';
import { TopSectionModule } from '../../components/top-section/top-section.module';
import { TrustpilotModule } from '../../components/trustpilot/trustpilot.module';
import { TemplateProductComponent } from './template-component/template-product.component';
import { TemplateProductRoutingModule } from './template-product-routing.module';
import { ProductCardModule } from '../../components//product-card/product-card.module';

@NgModule({
  declarations: [TemplateProductComponent],
  exports: [TemplateProductComponent],
  imports: [
    CommonModule,
    MarketingModule,
    InsertableModule,
    TemplateProductRoutingModule,
    BreadcrumbModule,
    LayoutModule,
    MultiCardModule,
    ReviewsModule,
    MultiColumnModule,
    RsaModule,
    RsaProductModule,
    TopProductModule,
    AccordionModule,
    EmailFormModule,
    TrustpilotModule,
    TabContentModule,
    ResourcesModule,
    SliderModule,
    SliderCardModule,
    FeefoModule,
    StandAloneCtaModule,
    CalculatorModule,
    TopSectionModule,
    AAFooterModule,
    ProductCardModule,
  ],
})
export class TemplateProductModule {}
