import type { OnInit } from '@angular/core';
import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { StorageService } from '@core-mkt/services/storage/storage.service';
import { faBars, faPhone, faSignInAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash-es';
import type { HeaderNavLink } from './header';
import { headerData, setAccountHeaderData } from './header-data';
import { setMobileHeaderData } from './mobile-header-data';

@Component({
  selector: 'ace-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @HostBinding('class.sticky-header') headerSticky = false;
  activateMobileMenu = false;
  faHamburger = faBars;
  faTimes = faTimes;
  faPhoneAlt = faPhone;
  faSignInAlt = faSignInAlt;
  headerData: HeaderNavLink[] = headerData;
  accountData: HeaderNavLink[] = [];
  mobileHeaderData: HeaderNavLink[] = [];
  resolverData: PageDataResponse;
  anonID = '';
  showDefaultNav = true;

  constructor(private readonly storageService: StorageService, private route: ActivatedRoute) {
    if (this.route.snapshot) {
      this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    }
  }

  get aaLogo(): string {
    return 'https://xgrit-ecom.imgix.net/ace/aa-nav-bar-logo.svg?auto=format,compress&v=1719499027';
  }

  get aaLogoBlue(): string {
    return 'https://xgrit-ecom.imgix.net/ace/aalogoBlue.svg?v=1727288452?auto=format,compress&v=1719499027';
  }

  ngOnInit(): void {
    if (this.resolverData.craftData?.headerData) {
      this.showDefaultNav = this.resolverData.craftData.headerData.hideNav;
      this.headerSticky = !this.resolverData.craftData.headerData.isNavSticky;
    }
    this.anonID = this.storageService.get('ajs_anonymous_id')?.split('"').join('');

    this.accountData = setAccountHeaderData(this.anonID);
    this.mobileHeaderData = setMobileHeaderData(this.anonID);

    if (this.resolverData?.craftData?.overrides?.aceHeaderPageSettings[0]?.hideNav) {
      this.showDefaultNav = false;
    }
  }
}
