import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InsertableModule } from '@aceable/core';
import { MarketingModule } from '@core-mkt/shared/components/marketing/marketing.module';

import { FooterModule } from '@core-mkt/brands/aa/src/app/components/footer/footer.module';
import { HeaderModule } from '@core-mkt/brands/aa/src/app/components/header/header.module';
import { AccordionModule } from '@core-mkt/shared/components/accordion/accordion.module';
import { AnchorButtonModule } from '@core-mkt/shared/components/anchor-button/anchor-button.module';
import { AnchorModule } from '@core-mkt/shared/components/anchor/anchor.module';
import { BannerModule } from '@core-mkt/shared/components/banner/banner.module';
import { BreadcrumbModule } from '@core-mkt/shared/components/breadcrumb/breadcrumb.module';
import { BundleModule } from '@core-mkt/shared/components/bundle/bundle.module';
import { EmailFormModule } from '@core-mkt/shared/components/email-form/email-form.module';
import { GraphicBannerModule } from '@core-mkt/shared/components/graphic-banner/graphic-banner.module';
import { LayoutModule } from '@core-mkt/shared/components/layout/layout.module';
import { MultiCardModule } from '@core-mkt/shared/components/multi-card/multi-card.module';
import { MultiColumnModule } from '@core-mkt/shared/components/multi-column/multi-column.module';
import { ResourcesModule } from '@core-mkt/shared/components/resources/resources.module';
import { ReviewsModule } from '@core-mkt/shared/components/reviews/reviews.module';
import { TopSectionModule } from '@core-mkt/shared/components/top-section/top-section.module';
import { TrustpilotModule } from '@core-mkt/shared/components/trustpilot/trustpilot.module';
import { TemplateBundlesRoutingModule } from './template-bundles-routing.module';
import { TemplateBundlesComponent } from './template-component/template-bundles.component';

@NgModule({
  declarations: [TemplateBundlesComponent],
  exports: [TemplateBundlesComponent],
  imports: [
    CommonModule,
    MarketingModule,
    InsertableModule,
    TemplateBundlesRoutingModule,
    HeaderModule,
    FooterModule,
    BannerModule,
    AnchorButtonModule,
    MultiCardModule,
    MultiColumnModule,
    LayoutModule,
    AccordionModule,
    ResourcesModule,
    TrustpilotModule,
    ReviewsModule,
    BundleModule,
    TopSectionModule,
    AnchorModule,
    EmailFormModule,
    BreadcrumbModule,
    GraphicBannerModule,
  ],
})
export class TemplateBundlesModule {}
