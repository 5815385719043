import { DOCUMENT } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { IconService } from '@core-mkt/services/iconService/icon.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import type Swiper from 'swiper';
import type { SwiperOptions } from 'swiper';
import type { SlideCard } from './slider-card';
import { SliderCardSection } from './slider-card';

@Component({
  selector: 'slider-card',
  templateUrl: './slider-card.component.html',
  styleUrls: ['./slider-card.component.scss'],
})
export class SliderCardComponent implements OnInit {
  @Input() data: SliderCardSection;
  theme: ThemeInterface;
  cellClass = '';
  sliderId = 'slider-' + Math.random().toString(16).slice(2);
  pagination = 'swiper-pagination-' + Math.random().toString(16).slice(2);
  showTitle = false;
  showDescription = false;
  centerCards = '';
  centerTransform = '';
  prevIcon = null;
  nextIcon = null;
  public swiperConfig: SwiperOptions = {
    navigation: true,
    slidesPerView: 'auto',
    freeMode: true,
    initialSlide: 0,
    centerInsufficientSlides: true,
  };

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    private iconService: IconService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  get carouselCellClass(): string {
    return this.cellClass;
  }

  get sliderDescription(): string {
    return this.data.sliderDescription;
  }

  get slides(): SlideCard[] {
    return this.data.slidesCards;
  }

  get sliderTitle(): string {
    return this.data.sliderTitle;
  }

  get displayAllCardMobile(): boolean {
    return this.data.displayAllCardMobile;
  }

  async ngOnInit(): Promise<void> {
    this.showTitle = this.data.sliderTitle !== null && this.data.sliderTitle.length > 0;
    this.showDescription = this.data.sliderDescription !== null && this.data.sliderDescription.length > 0;
    this.data.sliderTitle = this.redactor.bypassSanitizer(this.data.sliderTitle);
    this.data.sliderDescription = this.redactor.bypassSanitizer(this.data.sliderDescription);
    this.data.slidesCards.forEach((slide) => {
      slide.slideContent = this.redactor.bypassSanitizer(slide.slideContent);
      slide.hasBottomContent = new RegExp(`class=["'][^"']*\\bbottom-content\\b[^"']*["']`).test(slide.slideContent);
    });
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: null,
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    this.cellClass = this.data.slidesCards.length > 3 ? 'carousel-cell-small' : 'carousel-cell-big';
  }

  buttonData(slide: SlideCard): VisionButton {
    return {
      bgTheme: this.theme.root,
      buttonLink: slide.slideButtonLink,
      buttonText: slide.slideButtonLabel,
      buttonStyle: slide.slideButtonStyle,
      newTab: slide.slideButtonNewTab,
      nofollow: slide.slideButtonNofollow,
      noopener: false,
      noreferrer: false,
      fullWidth: false,
    };
  }

  addFullHeightToCarouselCell(): void {
    const carouselCells = this.document.querySelectorAll('.carousel-cell');
    carouselCells.forEach((cell) => {
      cell.classList.add('h-full');
    });
  }

  onSwiper(swiper: Swiper): void {
    this.prevIcon = this.iconService.getBrandIcon('slider-left');
    this.nextIcon = this.iconService.getBrandIcon('slider-right');
    //this is a cheat to add svg requested by design
    if (swiper?.navigation && swiper.navigation.prevEl && swiper.navigation.nextEl && this.prevIcon && this.nextIcon) {
      swiper.navigation.prevEl.innerHTML = this.prevIcon.icon;
      swiper.navigation.nextEl.innerHTML = this.nextIcon.icon;
    }
  }
}
