import { Component, Input } from '@angular/core';
import { faArrowRight, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { HeaderNavLink } from '../header/header';

@Component({
  selector: 'ace-header-navlink',
  templateUrl: './header-nav-link.component.html',
  styleUrls: ['./header-nav-link.component.scss'],
})
export class HeaderNavLinkComponent {
  @Input() data: HeaderNavLink;
  hovered = false;
  showMenu = false;
  tabShift: boolean;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  faArrowRight = faArrowRight;

  constructor() {}

  /**
   * Show dropdown menus when nav item is focused and enter is pressed
   */
  enterPress(): void {
    this.showMenu = !!this.data.items;
  }

  /**
    * Hide dropdown menus after the last item is unfocused/blurred and tabbed forward.
    * If the last item is tabbed backwards, the menu stays open.
    * If the first item is tabbed backwards, the menu closes.
    @param event The blur event that triggered the function.
    */
  onBlur(event): void {
    const isFirstSimpleItem = event.target.classList.contains('first-simple-item');
    const isLastSimpleItem = event.target.classList.contains('last-simple-item');

    // Update the visibility of the menus based on the tabbing direction and item position.
    if ((isLastSimpleItem && !this.tabShift) || (isFirstSimpleItem && this.tabShift)) {
      this.showMenu = false;
    }
  }

  /**
   * Checks if user is tabbing backwards
   * @param event The keydown KeyboardEvent that triggers the function
   */
  onKeyDown(event: KeyboardEvent): void {
    this.tabShift = event.shiftKey && event.key === 'Tab' ? true : false;
  }
}
