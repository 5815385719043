<div *ngIf="theme" class="slider-section {{ theme?.root }}">
  <div class="slider-inner-wrapper relative">
    <div *ngIf="showTitle" class="wysiwyg py-v-spacing tab:px-v-spacing max-w-vision mx-auto" [innerHtml]="sliderTitle">
    </div>
    <div *ngIf="showDescription" class="wysiwyg tab:px-v-spacing max-w-vision mx-auto"
      [innerHtml]="sliderDescription"></div>
      <div [class]="'text-ace-gray-900 slider-container' + (displayAllCardMobile ? ' hidden sm:block' : ' block')">
      <swiper [config]="swiperConfig" (swiper)="onSwiper($event)">
      <ng-template swiperSlide *ngFor="let slide of slides; let last = last" class="flex swiper-lazy" [class.last-slide]="last">
        <div [class]="'bg-white rounded slider-card shadow-md mb-v-spacing mr-v-spacing flex flex-col justify-between ' + (slide.hasBottomContent ? 'pb-12' : '')">
          <div>
            <div>
              <img *ngIf="slide.slideImage && slide.slideImage[0] && slide.slideImage[0].url"
                class="slider-full-image mx-auto" [src]="slide.slideImage[0].url"
                [alt]="slide.slideImage[0].altText ? slide.slideImage[0].altText : ''" [height]="160" [width]="160" />
            </div>
            <div *ngIf="slide.slideContent" class="wysiwyg wysiwyg-slide-card p-v-spacing"
              [innerHtml]="slide.slideContent"></div>
          </div>
          <div *ngIf="slide.slideButtonLabel" class="py-v-spacing px-v-spacing pt-2">
            <vision-button defaultStyle="primary" [data]="buttonData(slide)"></vision-button>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
    <div *ngIf="displayAllCardMobile"
      [class]="'text-gray-900' + (displayAllCardMobile ? ' block sm:hidden' : ' hidden')" class="flex flex-col mx-v-spacing">
      <div *ngFor="let slide of slides; let last = last" class="{{ carouselCellClass }} carousel-cell flex"
        [class.mb-v-spacing]="!last">
        <div class="bg-white p-v-spacing rounded shadow-xl w-full">
          <div>
            <img *ngIf="slide.slideImage && slide.slideImage[0] && slide.slideImage[0].url"
              class="slider-full-image mx-auto" [src]="slide.slideImage[0].url"
              [alt]="slide.slideImage[0].altText ? slide.slideImage[0].altText : ''" [height]="160" [width]="160" />
          </div>
          <div *ngIf="slide.slideContent" class="wysiwyg" [innerHtml]="slide.slideContent"></div>
          <div *ngIf="slide.slideButtonLabel" class="p-v-spacing">
            <vision-button defaultStyle="primary" [data]="buttonData(slide)"></vision-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
