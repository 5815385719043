<div class="{{ theme?.root }} bg-no-repeat bg-center bg-cover" [style]="theme?.bgImage">
  <div 
    class="flex flex-col m-auto px-v-spacing py-4" 
    [class.lg:flex-row]="data.emailFormImage && data.emailFormImage.length > 0 && fullForm" 
    [class.flex-col-reverse]="data.emailFormImage && data.emailFormImage.length > 0 && fullForm" 
    [ngClass]="fullForm ? 'container' : 'email-form'">
    <div class="w-full lg:w-1/2 lg:p-12 flex items-center justify-center" *ngIf="data.emailFormImage && data.emailFormImage.length > 0 && fullForm">

    <img
    *ngIf="data.emailFormImage.length > 0 && data.emailFormImage[0].url !== null"
    [src]="data.emailFormImage[0].url"
    [alt]="getAlt()"
          class=" object-cover pt-4 lg:pt-0"
          width="534"
          height="534"
        />
        </div>
  
  <div
    class="flex md:items-left flex-col  {{ fullForm ? 'thin-container' : 'lg:flex-row' }}"
    [class.justify-center]="!hasContent"
    [ngClass]="{'lg:w-1/2': data.emailFormImage.length > 0 && fullForm}"
  >
    <div
      *ngIf="hasContent"
      class="wysiwyg w-full pr-v-spacing content-center"
      [class.xs:p-v-spacing]="fullForm"
      [class.lg:mb-0]="!fullForm"
      [class.text-center]="data.emailFormImage && !data.emailFormImage.length > 0"
      [ngClass]="{ 'hidden md:block': hideContentOnMobile }"
      [innerHTML]="formContent"
    ></div>

    <div
      class="w-full p-x-spacing pv-3 content-center"
      [ngClass]="{
        'flex flex-col items-center py-v-spacing tab:px-v-spacing max-w-vision mx-auto': !hasContent,
        textHidden: hideContentOnMobile
      }"
    >
      <div
        *ngIf="formSubmitted && fullForm"
        class="parent-form__content py-v-spacing sm:px-v-spacing md:mr-v-spacing font-bold text-center text-vis-subtle mt-3"
      >
        Thank you for subscribing!
      </div>
      <div *ngIf="formSubmitted && !fullForm" class="parent-form__content md:mr-v-spacing font-bold text-vis-subtle mt-3">
        <p class="text-left lg:text-right">Thank you for subscribing!</p>
      </div>
      <form
        class="mx-auto flex justify-end items-center text-vis-regular flex-col {{
          formClasses
        }} "
        [class.p-v-spacing]="fullForm"
        [class.hidden]="formSubmitted"
        [class.lg:ml-0]="data.emailFormImage && data.emailFormImage.length > 0 && fullForm"
        [class.max-w-[512px].m-auto.pt-3]="fullForm"
        [formGroup]="signUpForm"
        (ngSubmit)="onSubmit()"
        novalidate
      >

        <!-- Users Name -->
        <div class="w-full relative flex mb-3" [class.sm:mb-0]="!fullForm" *ngIf="data.showNameField && fullForm">
          <input
            class="py-v-spacing outline-none text-sm text-vis-regular"
            [class.sm:w-auto]="!fullForm || (fullForm && !data.showNameField && !data.showParentEmailField)"
            [class.sm:mr-3]="!fullForm"
            [class.smush]="!fullForm && data.showNameField && data.showParentEmailField"
            [ngClass]="{
              '!border-ace-red-600 text-ace-red-600 form-error !mb-6': signUpForm.controls['name'].errors && signUpForm.controls['name'].touched
            }"
            id="input__name"
            placeholder="Name"
            formControlName="name"
          />
          <span
            *ngIf="signUpForm.controls['name'].errors && signUpForm.controls['name'].touched"
            class="text-ace-red-600 absolute text-xs form-error"
            [class.slim]="!fullForm"
          >
            Please enter a valid name.
          </span>
          <span *ngIf="signUpForm.controls['name'].errors && signUpForm.controls['name'].touched" class="flex w-auto grow">
            <fa-icon
              class="text-sm text-ace-red-600 pointer-events-none absolute form-error-icon"
              [class.thin]="!fullForm"
              [icon]="faTimesCircle"
            ></fa-icon>
          </span>
        </div>

        <!-- Email Address -->
        <div class="w-full relative flex" [class.mb-3]="fullForm" *ngIf="!data.hideEmailField">
          <input
            class="py-[26px] outline-none text-sm text-vis-regular"
            [class.sm:w-auto]="!fullForm || (fullForm && data.hideEmailField)"
            [class.sm:mr-3]="!fullForm"
            [ngClass]="{
              '!border-ace-red-600 text-ace-red-600 form-error !mb-6': signUpForm.controls['email'].errors && signUpForm.controls['email'].touched
            }"
            id="input__emailAddress"
            placeholder="Email Address"
            formControlName="email"
          />
          <span
            *ngIf="signUpForm.controls['email'].errors && signUpForm.controls['email'].touched"
            class="text-ace-red-600 absolute text-xs form-error"
            [class.slim]="!fullForm"
          >
            Please enter a valid email address.
          </span>
          <span *ngIf="signUpForm.controls['email'].errors && signUpForm.controls['email'].touched" class="flex w-auto grow">
            <fa-icon
              class="text-sm text-ace-red-600 pointer-events-none absolute form-error-icon"
              [class.thin]="!fullForm"
              [icon]="faTimesCircle"
            ></fa-icon>
          </span>
        </div>

        <!-- Phone Number -->
        <div class="w-full relative flex" [class.mb-3]="fullForm" *ngIf="(data.showPhoneNumberField && fullForm) || (data.showPhoneNumberField && data.hideEmailField && !fullForm)">
          <input
            class="w-full py-v-spacing outline-none text-sm text-vis-regular"
            [class.sm:w-auto]="!fullForm || (fullForm && !data.showNameField && !data.showPhoneNumberField)"
            [class.sm:mr-3]="!fullForm"
            [ngClass]="{
              '!border-ace-red-600 text-ace-red-600 form-error !mb-6': signUpForm.controls['phoneNumber'].errors && signUpForm.controls['phoneNumber'].touched
            }"
            id="input__phoneNumber"
            placeholder="Phone Number"
            formControlName="phoneNumber"
          />
          <span
            *ngIf="signUpForm.controls['phoneNumber'].errors && signUpForm.controls['phoneNumber'].touched"
            class="text-ace-red-600 absolute text-xs form-error"
            [class.slim]="!fullForm"
          >
            Please enter a valid phone number.
          </span>
          <span *ngIf="signUpForm.controls['phoneNumber'].errors && signUpForm.controls['phoneNumber'].touched" class="flex w-auto grow">
            <fa-icon
              class="text-sm text-ace-red-600 pointer-events-none absolute form-error-icon"
              [class.thin]="!fullForm"
              [icon]="faTimesCircle"
            ></fa-icon>
          </span>
        </div>

        <!-- Parents Email -->
        <div class="w-full relative flex" [class.mb-3]="fullForm" *ngIf="data.showParentEmailField && fullForm || (data.showParentEmailField && data.hideEmailField)">
          <input
            class="py-[26px] outline-none text-sm text-vis-regular"
            [class.sm:mr-3]="!fullForm"
            [class.sm:w-auto]="!fullForm || (fullForm && !data.showNameField && !data.showParentEmailField)"
            [class.!mb-6]="signUpForm.controls['parentEmail'].errors && signUpForm.controls['parentEmail'].touched && fullForm"
            [ngClass]="{
              '!border-ace-red-600 text-ace-red-600 form-error': signUpForm.controls['parentEmail'].errors && signUpForm.controls['parentEmail'].touched
            }"
            id="input__parentEmail"
            placeholder="Parent's Email (Optional)"
            formControlName="parentEmail"
          />
          <span
            *ngIf="signUpForm.controls['parentEmail'].errors && signUpForm.controls['parentEmail'].touched"
            class="text-ace-red-600 absolute text-xs form-error"
            [class.slim]="!fullForm"
          >
            Please enter a valid email address.
          </span>
          <span *ngIf="signUpForm.controls['parentEmail'].errors && signUpForm.controls['parentEmail'].touched" class="flex w-auto grow">
            <fa-icon
              class="text-sm text-ace-red-600 pointer-events-none absolute form-error-icon"
              [class.thin]="!fullForm"
              [icon]="faTimesCircle"
            ></fa-icon>
          </span>
        </div>
        <span class="w-full text-center mt-5 sm:mt-0" [class.md:w-auto]="!fullForm" [class.sm:mt-3]="fullForm">
          <input
            type="submit"
            class="px-v-spacing py-[18px] {{ ctaType }}-button cursor-pointer"
            [class.w-auto]="ctaType === 'tertiary'"
            [value]="ctaLabel"
          />
        </span>
        <span class="text-ace-red-600 text-xs absolute post-error" *ngIf="postErr"
          >There was an issue with your subscription. Please try again later.</span
        >
      </form>
    </div>
    </div>
  </div>
</div>