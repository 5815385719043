import { Component, Input } from '@angular/core';
import { ProductBuilderData } from './study-plan-builder.models';

@Component({
  selector: 'study-plan-builder',
  templateUrl: './study-plan-builder.component.html',
})
export class StudyPlanBuilderComponent {
  @Input() data: ProductBuilderData;

  get hasCarousel(): boolean {
    return this.data.carousel.length > 0;
  }

  get hasWysiwygContent(): boolean {
    return this.data.wyswygContent.length > 0;
  }

  get hasProductBuilderForm(): boolean {
    return this.data.productBuilder.length > 0;
  }
}
