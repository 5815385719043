import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Image } from '@core-mkt/interfaces/image';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { ResourceCard } from '../resources/resources';

@Component({
  selector: 'resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss'],
})
export class ResourceCardComponent implements OnInit {
  @Input() data: ResourceCard;
  faAngleRight = faAngleRight;
  faAngleRightStyles = { width: '16px', height: '32px' };
  resourceTextContent = undefined;

  constructor(
    private redactor: WysiwygRedactorService,
    private imgixService: ImgixService,
    private themeService: ThemeParserService,
  ) {}

  get image(): Image | undefined {
    return this.data.resourceImage?.[0];
  }

  get backgroundColor(): string {
    if (!this.data.resourceBackgroundColor) {
      return 'bg-white text-vis-regular';
    }
    if (typeof this.data.resourceBackgroundColor === 'string') {
      return this.data.resourceBackgroundColor;
    }
    return this.themeService.getDominantClassFromStyleList(this.data.resourceBackgroundColor);
  }

  get textContent(): string {
    return this.resourceTextContent;
  }

  get ctaStyle(): string {
    return this.data.resourceCtaStyle;
  }

  get ctaLabel(): string {
    return this.data.resourceCtaLabel ? this.data.resourceCtaLabel : 'Read More';
  }

  get ctaLink(): string {
    return this.data.resourceCtaLink;
  }

  get ctaTag(): string {
    return this.data.resourceCtaTag;
  }

  get ctaNewTab(): boolean {
    return this.data.resourceCtaLinkNewTab;
  }

  get ctaNofollow(): boolean {
    return this.data.resourceCtaLinkNofollow;
  }

  get buttonData(): VisionButton {
    return {
      bgTheme: this.backgroundColor,
      buttonLink: this.data.resourceCtaLink,
      buttonText: this.ctaLabel,
      buttonStyle: this.ctaStyle,
      useIcon: true,
      newTab: this.data.resourceCtaLinkNewTab,
      nofollow: this.data.resourceCtaLinkNofollow,
      noopener: this.data.resourceCtaLinkNewTab,
      noreferrer: this.data.resourceCtaLinkNewTab,
      ctaTag: this.data.resourceCtaTag,
      fullWidth: false,
    };
  }

  ngOnInit(): void {
    this.resourceTextContent = this.redactor.bypassSanitizer(this.data.resourceTextContent);
  }

  ctaClick(): void {
    if (this.data.resourceCtaLink) {
      window.location.href = this.data.resourceCtaLink;
    }
  }
}
