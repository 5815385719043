/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const FaqFragment = {
  FAQ: gql`
    fragment FaqQuery on baseComponents_aarpFaq_BlockType {
      answerOverride {
        ... on answerOverride_BlockType {
          primaryOwnerId
          questionNumber
          state
        }
      }
      backgroundImage {
        url
        ... on images_Asset {
          altText
        }
      }
      faqSubTitle
      faqTitle
      styles
      typeHandle
    }
  `,
};
