import type { OnInit } from '@angular/core';
import { Component, HostBinding, Inject, Input, PLATFORM_ID } from '@angular/core';
import { IconService } from '@core-mkt/services/iconService/icon.service';
import { VisionButton } from './vision-button';

@Component({
  selector: 'vision-button',
  templateUrl: './vision-button.component.html',
  styleUrls: [],
})
export class VisionButtonComponent implements OnInit {
  @Input() defaultStyle = 'primary'; // This property can be used in case there's no value coming from craft
  @Input() defaultText: string; // Same as defaultStyle
  @Input() className?: string; // Extra classes that can be added to the button
  @Input() data: VisionButton; // Description of the values on the interface file.
  @HostBinding('class.w-full') get isFullWidth(): boolean {
    return this.data.fullWidth;
  }
  @HostBinding('class.mx-auto') get isCenterAligned(): boolean {
    return this.data.buttonAlignment === 'center';
  }
  @HostBinding('class.mr-auto') get isLeftAligned(): boolean {
    return this.data.buttonAlignment === 'left';
  }
  @HostBinding('class.ml-auto') get isRightAligned(): boolean {
    return this.data.buttonAlignment === 'right';
  }
  readonly definedStyles = ['primary', 'secondary', 'tertiary'];
  readonly darkBackgrounds = ['bg-vis-dark', 'bg-ace-teal-600'];
  linkIcon = null;
  relValues = [];

  constructor(private iconService: IconService, @Inject(PLATFORM_ID) private platformId: Object) {}

  get styleClass(): string {
    // Gets the style class that is going to be set on the HTML
    //this is broken down into steps for easier debugging
    const agnosticStyles = this.definedStyles.includes(this.data.buttonStyle)
      ? this.data.buttonStyle
      : this.defaultStyle;
    let darkBackground = '';
    if (this.data.bgTheme) {
      darkBackground = this.darkBackgrounds.some((bg) => this.data.bgTheme.includes(bg)) ? '-dark' : '';
    }
    return agnosticStyles + darkBackground;
  }

  get text(): string {
    return this.data.buttonText ? this.data.buttonText : this.defaultText;
  }

  //If href is null or undefined, it should do nothing
  get buttonLink(): string {
    return this.data.buttonLink ? this.data.buttonLink : '#';
  }

  get useIcon(): boolean {
    return this.data.useIcon !== undefined && this.data.useIcon;
  }

  get buttonStyle(): string {
    // Gets the selected style for the button. or the default one.
    return this.definedStyles.includes(this.data.buttonStyle) ? this.data.buttonStyle : this.defaultStyle;
  }

  get rel(): string {
    return this.relValues.join(' ');
  }

  get target(): string {
    return this.data.newTab ? '_blank' : '';
  }

  get dataFormElementId(): string {
    return this.data.ctaTag ? this.data.ctaTag : '';
  }

  async ngOnInit(): Promise<void> {
    if (this.data.nofollow) {
      this.relValues.push('nofollow');
    }
    if (this.data.noreferrer) {
      this.relValues.push('noreferrer');
    }
    if (this.data.noopener) {
      this.relValues.push('noopener');
    }

    const iconRes = this.iconService.getBrandIcon('resourcesLinkIcon');

    if (iconRes) {
      import('@fortawesome/free-solid-svg-icons')
        .then((fa) => {
          this.linkIcon = fa[iconRes.icon];
        })
        .catch((err) => {
          console.error(err, 'icon not found');
        });
    }
  }
}
