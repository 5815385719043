<div
  class="flex flex-col justify-start w-full content-card max-w-[364px] items-baseline w-full p-v-spacing z-10 shadow-card rounded-md {{
    theme?.root
  }}"
>
  <div class="mt-0 mb-4 wysiwyg content-card__title" *ngIf="data.showTitle" [innerHTML]="data.cardTitle"></div>
  <div *ngIf="starRating" class="stars flex sm:flex-col md:flex-row lg:flex-col xl:flex-row items-left mb-v-spacing">
    <div class="star-container h-[1.5rem] min-w-[75px]">
      <fa-icon
        *ngFor="let i of [].constructor(starRating)"
        class="star__solid fa-1x"
        [icon]="fasStar"
        [styles]="faStarStyles"
      >
      </fa-icon>
      <fa-icon
        *ngFor="let i of [].constructor(5 - starRating)"
        class="star__regular pr-0.5 fa-1x"
        [icon]="farStar"
        [styles]="faStarStyles"
      >
      </fa-icon>
    </div>
    <span *ngIf="starCaption" class="text-xs pl-3 sm:pl-0 md:pl-3 lg:pl-0 xl:pl-3 flex items-center">{{
      starCaption
    }}</span>
  </div>
  <div *ngIf="textContent" class="wysiwyg mb-v-spacing {{ checkStyle }}" [innerHTML]="textContent"></div>
  <div *ngIf="showPricing" class="w-full product mt-auto mb-v-spacing wysiwyg  {{ checkStyle }}">
    <div class="font-normal text-sm leading-5 mb-1.5">{{ priceText }}</div>
    <p class="large flex items-center sm:flex-wrap">
      <span *ngIf="showOriginal" class="line-through text-vis-regular font-extrabold"
        >${{ product.apiData.originalPrice }}</span
      >
      <span class="font-extrabold pl-1.5 text-ace-teal-900"
        >${{ product.apiData.discountPrice | customNumber : '1.2-2' }}</span
      >
      <span *ngIf="showSavings" class="text-sm pl-1.5 content-card__savings">You save ${{ savings }}</span>
    </p>
  </div>
  <vision-button
    [class.mt-auto]="!showPricing"
    *ngIf="showCTA"
    defaultStyle="primary"
    [data]="buttonData"
  ></vision-button>
  <span *ngIf="isLearnMoreIncluded" class="contents">
    <vision-button
      [class]="lmLabel && lmLink ? '' : 'invisible hidden md:block'"
      defaultStyle="tertiary"
      class="mt-2"
      [data]="buttonLinkData"
    ></vision-button>
  </span>
  <div *ngIf="showDisclaimer" class="wysiwyg mt-6 p-4 bg-chalk w-full {{ checkStyle }}">{{ disclaimer }}</div>
</div>
