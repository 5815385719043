<div *ngIf="showHideCareers && departments && departments.length > 0" class="container">
  <div class="flex flex-col md:flex-row mx-auto">
    <div class="flex flex-col mb-auto py-v-spacing sm:px-v-spacing md:w-3/12 lg:w-4/12 w-full">
      <button
        class="
          careers__departments
          border-1
          mb-v-spacing
          p-1
          border border-ace-teal-800
          hover:bg-ace-teal-600 hover:text-vis-reverse
          focus:outline-none
        "
        [class]="department === 'all' ? 'text-vis-reverse bg-ace-teal-600 ' : 'text-ace-teal-800'"
        (click)="department = 'all'"
      >
        All Teams
      </button>
      <button
        class="
          careers__departments
          border-1
          mb-v-spacing
          p-1
          border border-ace-teal-800
          hover:bg-ace-teal-600 hover:text-vis-reverse
          focus:outline-none
        "
        [class]="department === dep ? 'text-vis-reverse bg-ace-teal-600 ' : 'text-ace-teal-800'"
        *ngFor="let dep of departments"
        (click)="department = dep"
      >
        {{ dep }}
      </button>
    </div>

    <div class="flex flex-col py-v-spacing sm:px-v-spacing md:w-9/12 lg:w-8/12 w-full">
      <div class="careers__jobs" *ngFor="let job of data.greenhouse.jobs">
        <div
          class="p-v-spacing mb-v-spacing bg-ace-grey-100"
          *ngIf="department === 'all' || department === job.departments[0].name"
        >
          <a
            class="text-ace-teal-800 text-md hover:text-ace-grey-800"
            [attr.href]="job.absolute_url"
            target="_blank"
            *ngIf="department === 'all' || department === job.departments[0].name"
            >{{ job.title }}</a
          >
          <p class="mt-v-spacing">{{ job.location.name }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
